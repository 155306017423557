import { useAppSelector } from '@redux/hooks';
import cx from 'classnames';
import React, { CSSProperties, useMemo, forwardRef } from 'react';
import { StickyChildArgs } from 'react-sticky';
import { Decorated } from 'types/vendor';
import SocialSharing from '../../../components/social_sharing';
import { useStorefrontFeatureScannability } from '../../../hooks/useStorefrontFeatureScannability';
import FavoritesButton from '../../vendor_info/components/favorites_button';
import Styles from '../styles.scss';
import { NavItemElements } from '../types';
import { NavMenuItems } from './NavMenuItems';
import NavItem from './nav_item';

interface NavMenuProps extends Pick<StickyChildArgs, 'style' | 'isSticky'> {
	children: NavItemElements;
}

export const NavMenu = forwardRef<HTMLUListElement, NavMenuProps>(
	(props: NavMenuProps, ref) => {
		const { children, style, isSticky } = props;
		const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
		const viewport = useAppSelector((state) => state.viewport);
		const membership = useAppSelector((state) => state.membership);
		const styles: CSSProperties = {
			...style,
			zIndex: 1,
		};

		const shouldShowScannability = useStorefrontFeatureScannability();

		return (
			<ul
				style={styles}
				className={cx(Styles.nav, {
					[Styles.stickyNav]: isSticky,
					[Styles.navHide]: !isSticky,
				})}
				id="nav-bar"
				ref={ref}
			>
				<NavItem title="Photos" />
				<NavMenuItems>{children}</NavMenuItems>
				{isSticky && (
					<li className={cx(Styles.navItem, Styles.saveAndShare)} key="share">
						<div className={Styles.favoritesButtonContainer}>
							<FavoritesButton membership={membership} vendor={vendor} />
						</div>
						{!shouldShowScannability && (
							<SocialSharing
								vendor={vendor}
								viewport={viewport}
								icon="share_ios"
							/>
						)}
					</li>
				)}
			</ul>
		);
	},
);
